import axios from 'axios';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import './styles_css/OrderForm.css';



const OrderForm = ({ cart, setSubmitted, currency, convertPrice }) => {

  const [formData, setFormData] = useState({
    durum: 'pending',
    fullName: '',
    address: '',
    companyName: '',
    email: '',
    indirim:'0',
    vergi:'0',
    cargo: '',
    otherCargo: '',
    otherPayment: '',
    kargoNumber: '',
    currency: currency,
    totalPrice: '',
    phoneNumber: '',
    paymentMethod: 'Cash',
    cart,

  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'cargo') {
      setFormData({
        ...formData,
        cargo: value,
        otherCargo: name === 'cargo' && formData.cargo === 'Other' ? value : formData.otherCargo,
      });
    } if (name === 'paymentMethod') {
      setFormData({
        ...formData,
        paymentMethod: value,
        otherCargo: name === 'paymentMethod' && formData.paymentMethod === 'OtherP' ? value : formData.otherPayment,
      });
    } else {
      setFormData({
        ...formData,
        totalPrice: convertPrice,
        [name]: value
      });
    }

  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post([process.env.REACT_APP_WEBSITE_URL,'/api/submit-order'].join(""), formData);
      console.log('Order submitted successfully:', response.data);
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting order:', error);
    }
  };

  const handleReturnToCart = () => {
    setSubmitted(false);
    window.location.href = '/cart';
  };

  const handlePhoneChange = (value) => {
    setFormData({
      ...formData,
      phoneNumber: value,
    });
  };

  return (
    <>
      <div className="frame-parent22">
        <div className="registration-from-parent22">
          <div className="registration-from22">Muşteri Kayıt Formu</div>
          <div className="please-fill-out22">Lütfen tüm formu doldurun</div>
        </div>

        <div className="frame-container22">
          <form className="frame-group22" onSubmit={handleSubmit}>

            <div className='form_divider_container'>

              <div className='form_divider'>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="fullName" className="wholesale22">Ad - Soyad</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22 custom-cargo-fields">
                    <input
                      type="text"
                      id="fullName"
                      name="fullName"
                      placeholder='Ad - Soyad'
                      className='searchbar-placeholder22'
                      value={formData.fullName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="phoneNumber" className="wholesale22">Telefon Numarası</label>
                  </div>
                  <div className="phonenumber-details">
                    <PhoneInput
                      specialLabel=''
                      country={'tr'}
                      value={formData.phoneNumber}
                      onChange={handlePhoneChange}
                      inputClass='phone-input-inputClass'
                      inputStyle={{width: '100%'}}
                      pattern='^(\\+\\d{1,2}\\s?)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'
                      containerClass='phone-input-containerClass'
                      searchClass='phone-input-searchClass'
                      dropdownClass='phone-input-dropdownClass'
                      searchStyle='phone-input-searchStyle'
                      required
                    />
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="email" className="wholesale22">Email</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      placeholder='Email'
                      className='searchbar-placeholder22'
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="address" className="wholesale22">Adres</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22">
                    <input
                      type="text"
                      id="address"
                      name="address"
                      placeholder='Adres'
                      className='searchbar-placeholder22'
                      value={formData.address}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>

              <div className='form_divider'>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="companyName" className="wholesale22">Şirket İsmi</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22">
                    <input
                      type="text"
                      id="companyName"
                      name="companyName"
                      placeholder='Şirket İsmi'
                      className='searchbar-placeholder22'
                      value={formData.companyName}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="cargo" className="wholesale22">Kargo Firmasi</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22 kargo">
                    <select
                      id="cargo"
                      name="cargo"
                      className="searchbar-placeholder22 custom-cargo-select-fields"
                      value={formData.cargo}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="">Select Cargo Firmasi</option>
                      <option value="Karanfil Kargo">Karanfil Kargo</option>
                      <option value="Danış Kargo">Danış Kargo</option>
                      <option value="63 Kargo">63 Kargo</option>
                      <option value="Atlas Kargo">Atlas Kargo</option>
                      <option value="Mng">Mng</option>
                      <option value="Yurt içi">Yurt içi</option>
                      <option value="Other">Farklı Firma</option>
                    </select>
                    {formData.cargo === 'Other' && (
                      <input
                        type="text"
                        id="otherCargo"
                        name="otherCargo"
                        placeholder="Kargo Firmasi Belirtiniz"
                        className="searchbar-placeholder22"
                        value={formData.otherCargo}
                        onChange={handleInputChange}
                        style={{ marginTop: '10px' }}
                        required
                      />
                    )}
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="kargoNumber" className="wholesale22">Kargo Numarasi</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22">
                    <input
                      type="text"
                      id="kargoNumber"
                      name="kargoNumber"
                      placeholder='Kargo Numarasi'
                      className='searchbar-placeholder22'
                      value={formData.kargoNumber}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>

                <div className="frame-div22">
                  <div className="full-name-wrapper22">
                    <label htmlFor="paymentMethod" className="wholesale22">Ödeme Yöntemi</label>
                  </div>
                  <div className="searchbar-placeholder-wrapper22 kargo">
                    <select
                      id="cargo"
                      name="paymentMethod"
                      className='searchbar-placeholder22 custom-cargo-select-fields'
                      value={formData.paymentMethod}
                      onChange={handleInputChange}
                      required
                    >
                      <option value="Cash">Nakit</option>
                      <option value="Rio">Rio</option>
                      <option value="Zolotaya Korona">Zolotaya Korona</option>
                      <option value="PayPal">PayPal</option>
                      <option value="Western Union">Western Union</option>
                      <option value="Skrill">Skrill</option>
                      <option value="TransferWise (Wise)">TransferWise (Wise)</option>
                      <option value="Revolut">Revolut</option>
                      <option value="MoneyGram">MoneyGram</option>
                      <option value="Payoneer">Payoneer</option>
                      <option value="Remitly">Remitly</option>
                      <option value="WorldRemit">WorldRemit</option>
                      <option value="Venmo">Venmo</option>
                      <option value="Cash App">Cash App</option>
                      <option value="OtherP">Farklı Ödeme</option>
                    </select>

                    {formData.paymentMethod === 'OtherP' && (
                      <input
                      type="text"
                      id="otherCargo"
                      name="otherPayment"
                      placeholder="Ödeme Yöntemi Belirtiniz"
                      className="searchbar-placeholder22"
                      value={formData.otherPayment}
                      onChange={handleInputChange}
                      style={{ marginTop: '10px' }}
                      required
                    />
                    )}
                  </div>
                </div>

              </div>

            </div>

            <div className='form_divider_footer'>
              <div className="frame-parent722">
                <div className="back-shopping-wrapper22" id="frameContainer">
                  <div onClick={handleReturnToCart} className="wholesale22">Geri Dön</div>
                </div>
                <div className="checkout-wrapper22" id="frameContainer1">
                  <button type="submit" className="wholesale22">Gönder</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
};

export default OrderForm;
