import React from 'react';
import { Link } from 'react-router-dom';
import './styles_css/ThankYou.css';


const ThankYouPage = () => {
  return (
    <div class="thank-you-page">
      <div class="thank-you-page-inner">
        <div class="frame-wrapper">
          <div class="teekr-ederiz-parent">
            <b class="teekr-ederiz">Teşekkürler</b>
            <div class="sipariiniz-alnd">Siparişiniz Alınmıştır!</div>
            <div class="continue-shopping-wrapper" id="frameContainer">
              <Link to="/" class="continue-shopping">Alışverişe Devam Edin</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
