import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useCurrencyContext } from './CurrencyContext';
import PrintableReceipt from './PrintableReceipt';
import './styles_css/Order.css';




const Order = ({ order, /*onApprove,*/ onDelete }) => {

  const { currency, convertPrice } = useCurrencyContext();
  const [discountPercentage, setDiscountPercentage] = useState(order.indirim || 0);
  const [taxesPercentage, setTaxesPercentage] = useState(order.vergi || 0);
  const [appliedDiscount, setAppliedDiscount] = useState(order.appliedDiscount || 0);
  const [appliedTaxes, setAppliedTaxes] = useState(order.appliedTaxes || 0);
  const [totalPrice, setTotalPrice] = useState(order.totalPrice);
  const [isEditing, setIsEditing] = useState(true);
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [paymentAmount, setPaymentAmount] = useState(order.paymentAmount || 0);
  const [isEditingPayment, setIsEditingPayment] = useState(false);
  const [remainingBalance, setRemainingBalance] = useState(order.totalPrice - (order.paymentAmount || 0));
  const [isApproving, setIsApproving] = useState(false);

  const originalPrice = useState(order.totalPrice || 0);

  const myPrintBox = useRef(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get([process.env.REACT_APP_WEBSITE_URL, '/api/allOrders'].join(""));
        const ordersData = response.data;
        const pendingOrders = ordersData.map(order => ({
          ...order,
          durum: order.durum || 'pending', // Default durum is pending
        }));
        setOrders(pendingOrders || []);
        setFilteredOrders(pendingOrders.filter(order => order.durum === 'pending') || []); // Show only pending orders by default
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  useEffect(() => {
    setDiscountPercentage(order.indirim || 0);
    setTaxesPercentage(order.vergi || 0);
    setAppliedDiscount(order.appliedDiscount || 0);
    setAppliedTaxes(order.appliedTaxes || 0);
    setTotalPrice(order.totalPrice);
    setPaymentAmount(order.paymentAmount || 0);
    setRemainingBalance((parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount()).toFixed(2) - order.paymentAmount);
  }, [order]);


  const handlePaymentAmountChange = (e) => {
    let value = e.target.value;
    value = isNaN(value) ? 0 : value;
    let theTotalPrice = parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount();
    value = Math.max(0, Math.min(value, (theTotalPrice).toFixed(2)));

    setPaymentAmount(parseFloat(value));
    setRemainingBalance(parseFloat((parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount() - value).toFixed(2)));
  };

  const formatOrderId = (id) => {
    const paddedId = `000000${id}`.slice(-7);
    return `RG${paddedId}`;
  };

  // const handlePrintReceipt = () => {
  //   const printableElement = document.getElementById('printable-receipt');
  //   if (printableElement) {
  //     const html = printableElement.innerHTML;
  //     const windowContent = `<html><head><title>Sipariş Detayları</title></head><body>${html}</body></html>`;
  //     const printWindow = window.open('', '', 'height=400,width=600');
  //     printWindow.document.write(windowContent);
  //     printWindow.document.close();
  //     printWindow.print();
  //   } else {
  //     console.error('Printable element not found.');
  //   }
  // };


  // Calculate total price after applying discount ONLY
  const calculateTotalPrice = (basePrice) => {
    const discountAmount = basePrice * (discountPercentage / 100);
    const discountedPrice = basePrice - discountAmount;
    // const taxesAmount = discountedPrice * (taxesPercentage / 100);
    // const finalPrice = discountedPrice + taxesAmount;
    const finalPrice = discountedPrice;
    return finalPrice;
  };



  const calculateDiscountAmount = () => {
    return order.totalPrice * (discountPercentage / 100);
  };

  const calculateTaxesAmount = () => {
    const discountedPrice = order.totalPrice - calculateDiscountAmount();
    return discountedPrice * (taxesPercentage / 100);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };
  const jsSend = async (order) => {
    await axios.post([process.env.REACT_APP_WEBSITE_URL, '/api/change-order-status'].join(""), {
      orderInfo: order
    });
  }

  const handleApplyChanges = () => {
    const discountAmount = calculateDiscountAmount();
    const taxesAmount = calculateTaxesAmount();
    const finalPrice = calculateTotalPrice(order.totalPrice);

    setAppliedDiscount(discountAmount);
    setAppliedTaxes(taxesAmount);
    setTotalPrice(finalPrice);
    setIsEditing(false);

    // Update the order object with the applied discount and taxes
    order.appliedDiscount = discountAmount;
    order.appliedTaxes = taxesAmount;
    order.newPrice = finalPrice;
    order.indirim = discountPercentage;
    order.vergi = taxesPercentage;
    order.paymentAmount = paymentAmount;
    order.remainingBalance = remainingBalance;


    // order.durum = 'approved';
  };
  //jsSend(order);

  // Consoles
  console.log('order', order);
  console.log('totalPrice', totalPrice);
  console.log('discountPercentage', discountPercentage);
  console.log('taxesPercentage', taxesPercentage);
  console.log('appliedDiscount', appliedDiscount);
  console.log('appliedTaxes', appliedTaxes);
  console.log('calculateDiscountAmount', calculateDiscountAmount());
  console.log('calculateTaxesAmount', calculateTaxesAmount());
  console.log('calculateTotalPrice', calculateTotalPrice(order.totalPrice));
  console.log('order.indirim: ', order.indirim)
  console.log('order.vergi: ', order.vergi)
  console.log('originalPrice', originalPrice[0])
  // 

  const handleEditPayment = () => {
    setIsEditingPayment(true);
  };

  const handleSavePayment = () => {
    setIsEditingPayment(false);
    order.paymentAmount = paymentAmount;
    order.remainingBalance = remainingBalance;
    try {
      // Update order status on the server
      jsSend(order);
      console.log('Order payment amount updated successfully');
    } catch (error) {
      console.error('Error updating order payment amount:', error);
    }
  };

  const convertPriceSelf = (price, currency) => {
    // try {
    //   const response = await axios.get(`${process.env.REACT_APP_WEBSITE_URL}/api/currency-rates`);
    //   const rate = response.data.rates[currency];
    //   const finalPrice = rate ? ((price * rate).toFixed(2)) : price;
    //   console.log('finalPriceDJFGŞSDJHŞKFG', finalPrice);
    //   return finalPrice; // Return the final price
    // } catch (error) {
    //   console.error("Error fetching currency rates:", error);
    //   return price; // Return the original price in case of an error
    // }
    const rates = {
        "TRY": 1,
        "USD": 0.030303030303,
        "EUR": 0.02857142857
    }
      const rate = rates[currency];
      const finalPrice = rate ? ((price * rate).toFixed(2)) : price;
      // console.log('finalPriceDJFGŞSDJHŞKFG', finalPrice);
      return finalPrice / 2; // Return the final price

  };

  const handlePrint = () => {
    const printableOrder = (
      <PrintableReceipt
        id={order.id}
        order={order}
        calculateTotalPrice={calculateTotalPrice}
        calculateTaxesAmount={calculateTaxesAmount}
        calculateDiscountAmount={calculateDiscountAmount}
      />
    );

    const printWindow = window.open('', '', '');
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Element</title>
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <style>
          .kargosevk {
            line-height: 0%;
            width: max-content;
          }
          .adres {
            width: 90%;
            line-height: normal;
          }
 body{transform-origin:0% 0%;} p {font-size:x-small; line-height: 70%} h1,h2,h3,h4,h5,h6{line-height:0%} table {font-size:x-small;} .heading123 {height: 25px; font-size: larger;} .invoice {font-family: Arial, sans-serif;width: 95%;margin: auto;}header, .customer-info, .cargo-info {border-bottom: 1px solid #ddd;padding: 10px 0;}.cargo-info{width: 200px;padding-left: 30px;}.flexRow{display: flex;justify-content: space-between;}.flexRowLast{display: flex;justify-content:flex-end;}table {width: 100%;border-collapse: collapse;margin: 20px 0;}th, td {border: 1px solid #ddd;padding: 8px;text-align: left;}th {background-color: #f2f2f2;}img {width: 30px;height: auto;}.totals {text-align: right;}.totals p {margin: 5px 0;width: 150px;float: right;}.totals p:nth-child(2){text-align: left;width:100px;}          </style>
        </head>
        <body>
          <div id="printableContent"></div>
        </body>
      </html>
    `);

    ReactDOM.render(printableOrder, printWindow.document.getElementById('printableContent'));

    printWindow.document.close();
    printWindow.print();
  };

  const handleDiscountChange = (e) => {
    let value = parseFloat(e.target.value);
    value = isNaN(value) ? 0 : value; // Default to 0 if NaN
    value = Math.max(0, Math.min(value, 100)); // Ensure value is between 0 and 100
    setDiscountPercentage(value);
  };

  const handleTaxesChange = (e) => {
    let value = parseFloat(e.target.value);
    value = isNaN(value) ? 0 : value; // Default to 0 if NaN
    value = Math.max(0, Math.min(value, 100)); // Ensure value is between 0 and 100
    setTaxesPercentage(value);
  };

  const approveOrder = async (orderId) => {

    const order = orders.find(order => order.id === orderId);
    console.log(order);

    if (!order) {
      console.log(`Order not found with ID: ${orderId}`);
      return;
    }

    order.vergi = taxesPercentage;
    order.indirim = discountPercentage;
    order.durum = "approved";
    setIsApproving(true);
    try {
      // Update order status on the server
      jsSend(order);
      console.log('Order status updated successfully');
    } catch (error) {
      console.error('Error updating order status:', error);
    } finally {
      setIsApproving(false);
    }

    for (const cartItem of order.cart) {
      const { groupedInventoryItemIDByColors, selectedProductColor } = cartItem;

      if (!groupedInventoryItemIDByColors[selectedProductColor]) {
        console.log(`No inventory item IDs found for color: ${selectedProductColor}`);
        continue;
      }

      for (const inventoryItemId in groupedInventoryItemIDByColors[selectedProductColor]) {
        const { stock, inventory_item_id } = groupedInventoryItemIDByColors[selectedProductColor][inventoryItemId];
        console.log(`Inventory Item ID: ${inventory_item_id}, Stock: ${stock}`);

        try {
          // Adjust inventory level for each inventory item
          await axios.post([process.env.REACT_APP_WEBSITE_URL, '/api/adjust-inventory'].join(""), {
            location_id: 64086966323,
            inventory_item_id: inventory_item_id,
            available_adjustment: -cartItem.quantity // Adjust stock by the quantity from the order
          });

          console.log(`Inventory level adjusted for Inventory Item ID: ${inventory_item_id}`);
        } catch (error) {
          console.error('Error adjusting inventory level:', error);
        }
      }
    }
    window.location.reload();
  };

  return (
    <div className="order">
      <div className="order-header">
        <h3>Sipariş #{formatOrderId(order.id)}</h3>
        <p className="order-date">{new Date(order.timestamp).toLocaleString()}</p>
      </div>
      <div className="order-details">
        <div className='divider-info-actions'>
          <div className="customer-info">
            <h4>Müşteri Bilgileri:</h4>
            <p><strong>Ad:</strong> {order.fullName}</p>
            <p><strong>Adres:</strong> {order.address}</p>
            <p><strong>Şirket:</strong> {order.companyName}</p>
            <p><strong>Email:</strong> {order.email}</p>
            <p><strong>Telefon:</strong> +{order.phoneNumber}</p>
            <p><strong>Para Birimi:</strong> {order.currency}</p>
            {order.cargo != 'Other' ? (
              <p><strong>Kargo Şirketi:</strong> {order.cargo}</p>
            ) : (
              <p><strong>Kargo Şirketi:</strong> {order.otherCargo}</p>
            )}
            <p><strong>Kargo Numarası:</strong> {order.kargoNumber}</p>
            {order.paymentMethod != 'OtherP' ? (
                <p><b>Ödeme Yöntemi:</b> {order.paymentMethod}</p>
              ) : (
                <p><b>Ödeme Yöntemi:</b> {order.otherPayment}</p>
              )}
          </div>
          <div className="order-actions">
            {order.durum === 'pending' && (
              <>
                <div className="discount-taxes">
                  <div className="input-group">
                    <label htmlFor="discount">İndirim <span className='view-propery'>≈ -{calculateDiscountAmount().toFixed(2)} {order.currency}</span></label>
                    <input
                      id="discount"
                      type="number"
                      value={discountPercentage}
                      onChange={handleDiscountChange}
                      placeholder="Discount %"
                      disabled={!isEditing}
                    />
                  </div>

                  <div className="input-group">
                    <label htmlFor="taxes">Vergi (%{order.vergi})<span className='view-propery'>≈ {calculateTaxesAmount().toFixed(2)} {order.currency}</span></label>
                    <input
                      id="taxes"
                      type="number"
                      value={taxesPercentage}
                      onChange={handleTaxesChange}
                      placeholder="Taxes %"
                      disabled={!isEditing}
                    />
                  </div>

                  <div className='cta-buttons'>
                    {isEditing ? (
                      <button className="approve" onClick={handleApplyChanges}>Uygula</button>
                    ) : (
                      <button className="edit" onClick={handleEdit}>Düzenle</button>
                    )}
                  </div>
                </div>
                <div className='cta-buttons'>
                  <button className="approve" onClick={() => approveOrder(order.id)} disabled={isApproving}>{isApproving ? 'Onaylanıyor...' : 'Onayla'}</button>
                  <button className="delete" onClick={onDelete} disabled={isApproving}>Sil</button>
                </div>
              </>
            )}
            {order.durum === 'approved' && (

              <>
                <div className='orders-info'>
                  <table className="order-payment-table">
                    <thead>
                      <tr>
                        <th>Ödeme Bilgileri</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><strong>Ödenen Tutar:</strong></td>
                        <td>
                          {isEditingPayment ? (
                            <>
                              <label>
                                <input
                                  type="number"
                                  value={paymentAmount}
                                  onChange={handlePaymentAmountChange}
                                  min="0"

                                  max={isNaN(parseFloat((parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount()).toFixed(2))) ? order.totalPrice : parseFloat((parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount() + 1).toFixed(2))}
                                />
                              </label>
                              <button onClick={handleSavePayment}>Kaydet</button>
                            </>
                          ) : (
                            <>
                              {order.paymentAmount} {order.currency}
                            </>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td><strong>Kalan Tutar:</strong></td>
                        <td>{order.remainingBalance} {order.currency}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="order-actions-buttons">
                  <button className="print-button" onClick={handlePrint}>Print Receipt</button>
                  <button onClick={handleEditPayment}>Ödeme Düzenle</button>
                </div>

              </>
            )}
          </div>
        </div>

        <div className='divider-info-actions'>
          <div className="cart-items">
            <h4>Sepetteki Ürünler:</h4>
            <table className="items-table">
              <thead>
                <tr>
                  <th>Görsel</th>
                  <th>Başlık</th>
                  <th>Renk</th>
                  <th>Fiyat</th>
                  <th>Adet</th>
                  <th>SKU</th>
                </tr>
              </thead>
              <tbody>
                {order.cart.map((item, idx) => (
                  <tr key={idx}>
                    {(item.variants[0].photoUrl !== null && item.variants[0].photoUrl !== '') ? (
                      <td> <img className='product-image' alt={item.title} src={item.variants[0].photoUrl} /></td>
                    ) : (
                      <td> <img className='product-image' alt={item.title} src='/no-image.svg' /> </td>
                    )}
                    <td>{item.title}</td>
                    <td>{item.selectedProductColor}</td>
                    <td>{convertPriceSelf(item.variants[0].price, order.currency).toFixed(2)} {order.currency}</td>
                    <td>{item.quantity} Seri</td>
                    <td>{item.skus[0]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="info">
            <div ref={myPrintBox} className="price-table">
              <div className="row">
                <div className="label"><strong>Toplam Tutar:</strong></div>
                <div className="value">
                  {order.durum === 'pending' && (
                    <>
                      {originalPrice} {order.currency}
                    </>
                  )}
                  {order.durum === 'approved' && (
                    <>
                      {originalPrice} {order.currency}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="label"><strong>Vergi (%{order.vergi}):</strong></div>
                <div className="value">
                  <span className='view-property'>{calculateTaxesAmount().toFixed(2)} {order.currency}</span>
                </div>
              </div>
              <div className="row">
                <div className="label"><strong>İndirim:</strong></div>
                <div className="value">
                  <span className='view-property'>-{calculateDiscountAmount().toFixed(2)} {order.currency}</span>
                </div>
              </div>
              <div className="row">
                <div className="label"><strong>KDV Dahil Toplam Tutar:</strong></div>
                <div className="value">
                  {order.durum === 'pending' && (
                    <>
                      {(parseFloat(totalPrice) + calculateTaxesAmount()).toFixed(2)} {order.currency}
                    </>
                  )}
                  {order.durum === 'approved' && (
                    <>
                      {(parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount()).toFixed(2)} {order.currency}
                    </>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="label"><strong>Ödenecek Tutar:</strong></div>
                <div className="value">
                  {order.durum === 'pending' && (
                    <>
                      {(parseFloat(totalPrice) + calculateTaxesAmount()).toFixed(2)} {order.currency}
                    </>
                  )}
                  {order.durum === 'approved' && (
                    <>
                      {(parseFloat(order.totalPrice) - calculateDiscountAmount() + calculateTaxesAmount()).toFixed(2)} {order.currency}
                      </>
                  )}
                </div>
              </div>
            </div>
          </div>


        </div>

      </div>
    </div>
  );
};

export default Order;