import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import OrderForm from './components/OrderForm';
import ThankYouPage from './components/ThankYou';
import './styles/CartPage.css';

const CartPage = ({ cart, setCart, currency, convertPrice }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [showOrderForm, setShowOrderForm] = useState(false);

  const handleQuantityChange = (index, amount) => {
    const newCart = [...cart];
    const product = newCart[index];
    const colorVariations = product.variants.filter(variant => variant.color === product.selectedProductColor);
    const lowestStock = Math.min(...colorVariations.map(variant => variant.stock));

    const newQuantity = (newCart[index].quantity || 1) + amount;
    product.quantity = Math.max(1, Math.min(newQuantity, lowestStock));
    setCart(newCart);
  };

  if (submitted) {
    return <ThankYouPage />;
  }

  const filteredCart = cart.filter((product) =>
    product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    product.skus.some((sku) => sku.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  const totalPrice = filteredCart.reduce((sum, product) => {
    const productPrice = parseFloat(product.prices[0]) || 0;
    const variationsCount = product.variants.filter(variant => variant.color === product.selectedProductColor).length; // Count the number of variations
    return sum + productPrice * variationsCount * (product.quantity || 1);
  }, 0);


  return (
    <div className="cart-page6">
      {showOrderForm ? (
        <OrderForm
          cart={cart}
          setSubmitted={setSubmitted}
          currency={currency}
          convertPrice={convertPrice(totalPrice)}
        />
      ) : (
        <>
          <div className="carted-products6">
            {filteredCart.length === 0 ? (
              <p className="empty-cart-message6">Sepetiniz Boş</p>
            ) : (
              <>
                {filteredCart.map((product, index) => {
                  const colorVariations = product.variants.filter(variant => variant.color === product.selectedProductColor);
                  const lowestStock = Math.min(...colorVariations.map(variant => variant.stock));

                  return (
                    <div key={product.variants.variant_id} className="product-item36">
                      <div className='image_product_info'>
                        <div className="image-frame6">
                          <img
                            className="image-icon56"
                            loading="lazy"
                            alt={product.title}
                            src={product.photoUrl && product.photoUrl !== ''
                              ? product.photoUrl
                              : '/no-image.svg'}
                          />
                        </div>
                        <div className="products-info6">
                          <b className="product-name36">{product.title}</b>
                          <div className='collector_info'>
                            <div className="product-color-filter-container6">
                              <div className="product-color-filter6">
                                <div className="product-price-container6">
                                  <b className="price-title6">Renk:</b>
                                  <div className="price-container6">
                                    <b className="price6">{product.selectedProductColor}</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="product-price-container6">
                              <b className="price-title6">SKU:</b>
                              <div className="price-container6">
                                <b className="price6">{product.skus.join(', ')}</b>
                              </div>
                            </div>
                            <div className="product-price-container6">
                              <b className="price-title6">Fiyat:</b>
                              <div className="price-container6">

                                <b className="price6">{convertPrice(product.prices.join(', '))} {currency}</b>
                              </div>
                            </div>
                            <div className="product-price-container6">
                              <b className="price-title6">Paket:</b>
                              <div className="price-container6">
                                <b className="price6">{colorVariations.length} adet</b>
                              </div>
                            </div>
                            {/* <div className="product-price-container6">
                              <b className="price-title6">Maksimum Adet:</b>
                              <div className="price-container6">
                                <b className="price6">{lowestStock}</b>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className='controls'>
                        <div className="quantity-controls6">
                          <div className="plus-circle-parent6">
                            <img onClick={() => handleQuantityChange(index, 1)} className="plus-circle-icon6" alt="" src="plus-circle-black.svg" />
                            <div className="parent6">
                              <b className="b">{product.quantity}</b>
                            </div>
                            <img onClick={() => handleQuantityChange(index, -1)} className="minus-circle-icon6" alt="" src="minus-circle-black.svg" />
                          </div>
                        </div>
                        <div className="remove-item6" onClick={() => setCart(cart.filter((_, i) => i !== index))}>
                          <img src="trash-2-black.svg" alt="Remove item" />
                        </div>
                      </div>
                    </div>
                  );
                })}
                <div className='emre'>
                  <div>
                    <div className="total-price-container6">
                      <b className="total-price-title6">Toplam Fiyat: </b>
                      <b className="price-container-total6">
                        {convertPrice(totalPrice)} {currency}
                      </b>
                    </div>
                    {/* Create a div to add the KDV not now */}
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="frame-parent9">
            <Link to="/" className="back-shopping-wrapper9" id="frameContainer9">
              <div className="back-shopping91">Alışverişe Dön</div>
            </Link>
            <div className="checkout-wrapper9" id="frameContainer19" onClick={() => setShowOrderForm(true)}>
              <div className="back-shopping9">Siparişi Tamamlayın</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CartPage;
