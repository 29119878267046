const customStyles = {
  control: (provided, state) => ({
    ...provided,
    border: '1px solid #000',
    boxShadow: state.isFocused ? '0 0 0 1px #000' : 'none',
    color: '#000',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 20px',
    margin: '0 2px',
    width: '100%',
    // minWidth: '350px',
    transition: 'width 0.3s ease',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0 2px',
    color: '#000',
    width: '100%',
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: '8px',
    border: '1px solid #000',
    backgroundColor: '#eee',
    zIndex: 9999,
    maxHeight: '300px',
    transition: 'max-height 0.3s ease',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#000' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
    '&:hover': {
      backgroundColor: state.isSelected ? '#000' : '#eee',
    },
    width: '100%',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#000',
    width: '100%',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#000',
    width: '100%',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#000',
    '&:hover': {
      color: '#000',
    },
  }),
  // Media query for screens larger than 768px
  '@media (min-width: 768px)': {
    control: (provided) => ({
      ...provided,
      width: 'auto',
    }),
    menu: (provided) => ({
      ...provided,
      maxWidth: '400px',
    }),
  },
};

export default customStyles;
