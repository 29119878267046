import React from 'react';
//import './styles_css/PrintableReceipt.css';
// I copied this css file into the handlePrint() by hand
// I dont know why it did not work

const divider = 2.5;

const PrintableReceipt = ({ id, order, calculateTotalPrice, calculateTaxesAmount, calculateDiscountAmount }) => {
  // Function to format order ID
  const formatOrderId = (id) => {
    const paddedId = `000000${id}`.slice(-7);
    return `RG${paddedId}`;
  };

  const convertPriceSelf = (price, currency) => {

    const rates = {
      "TRY": 1,
      "USD": 0.030303030303,
      "EUR": 0.02857142857
    }
    const rate = rates[currency];
    const finalPrice = rate ? ((price * rate).toFixed(2)) : price;
    return finalPrice / divider; // Return the final price

  };

  let adetCount = 0;
  {
    order.cart.map((item, idx) => (
      adetCount += item.quantity
    ))
  }


  return (
    <div className="invoice">
      <div className='heading123'>
        <div className='flexRow'>
          <h5>SİPARİŞ NO: #{formatOrderId(order.id)}</h5>
          <h6>TARİH: {order.timestamp}</h6>
        </div>
      </div>

      <section className="customer-info">
        <h5>SATICI / ALICI: RGBrand</h5>
        <p><b>ADRES:</b> MAĞAZA BİLGİSİ</p>
        <p><b>TELEFON:</b> MAĞAZA BİLGİSİ</p>
        <p><b>MAIL:</b> MAĞAZA BİLGİSİ</p>
      </section>
      <div>
        <section className="customer-info">
          <div className='flexRow'>
            <section>
              <h5>SEVK ADRESİ / MÜŞTERİ</h5>
              <p><b>AD | ŞİRKET: </b>{order.fullName} | {order.companyName}</p>
              <p className='adres'><b>ADRES:</b>{order.address}</p>
              <p><b>TELEFON: </b>+{order.phoneNumber}</p>
              <p><b>MAIL: </b>{order.email}</p>
            </section>
            <section>
              <h5 className='kargosevk'>KARGO / SEVK</h5>
              {order.cargo != 'Other' ? (
                <p><strong>KARGO:</strong> {order.cargo}</p>
              ) : (
                <p><strong>KARGO:</strong> {order.otherCargo}</p>
              )}
              <p><b>KOD:</b> {order.kargoNumber}</p>
              {order.paymentMethod != 'OtherP' ? (
                <p><b>ÖDEME:</b> {order.paymentMethod}</p>
              ) : (
                <p><b>ÖDEME:</b> {order.otherPayment}</p>
              )}
              <p><b>DÖVİZ:</b> {order.currency}</p>
            </section>
          </div>
        </section>

      </div>


      <table>
        <thead>
          <tr>
            <th>GÖRSEL</th>
            <th>SKU</th>
            <th>ÜRÜN ADI</th>
            <th>RENK</th>
            <th>SERİ</th>
            <th>FİYAT</th>
            <th>TUTAR</th>
          </tr>
        </thead>
        <tbody>
          {order.cart.map((item, index) => (
            <tr key={index}>
              <td><img src={item.variants[0].photoUrl || '/no-image.svg'} alt={item.variants.title} /></td>
              <td>{item.skus[0]}</td>
              <td>{item.title}</td>
              <td>{item.selectedProductColor}</td>
              <td>{item.quantity} Seri</td>
              <td>{convertPriceSelf(item.variants && item.variants[0] ? item.variants[0].price : 0, order.currency).toFixed(2)} {order.currency}</td>
              <td>{convertPriceSelf(item.variants && item.variants[0] ? item.variants[0].price * (item.variants.length / Object.keys(item.groupedInventoryItemIDByColors).length) * item.quantity : 0, order.currency).toFixed(2)} {order.currency}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={4}></td>
            <td><b>Total: </b>{adetCount}</td>
            <td colSpan={2}></td>
          </tr>
        </tbody>
      </table>
      <footer>
        <div className="totals">
          <div className='flexRowLast'><b><p>ALT TOPLAM :</p></b>   &nbsp;<p>{order.totalPrice} {order.currency}</p></div>
          <div className='flexRowLast'><b><p>VERGİ ORANI :</p></b>  &nbsp;<p>{order.vergi}%</p></div>
          <div className='flexRowLast'><b><p>İNDİRİM :</p></b>      &nbsp;<p>{order.indirim}%</p></div>
          <div className='flexRowLast'><b><p>TOPLAM :</p></b>       &nbsp;<p>{parseFloat((order.totalPrice - (order.totalPrice * order.indirim / 100)) * ((order.vergi + 100) / 100)).toFixed(2)} {order.currency}</p></div>
        </div>
      </footer>
    </div>
  );
};

export default PrintableReceipt;