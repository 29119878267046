// CurrencyContext.js
import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import dotenv from 'dotenv';

dotenv.config();
console.log(process.env);

const CurrencyContext = createContext();

export const useCurrency = () => {
  const [currency, setCurrency] = useState('TRY'); // Default currency is TRY
  const [exchangeRates, setExchangeRates] = useState({ USD: 1, EUR: 1 }); // Default rates, will be updated from API

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        // console.log(" sdfsdfsdfs: "+ process.env.REACT_APP_WEBSITE_URL);
        const response = await axios.get([process.env.REACT_APP_WEBSITE_URL,'/api/currency-rates'].join(""));
        setExchangeRates(response.data.rates);
      } catch (error) {
        console.error('Error fetching exchange rates', error);
      }
    };

    fetchExchangeRates();
  }, []);

  // Function to convert price based on selected currency
  const convertPrice = (price) => {
    const rate = exchangeRates[currency];
    return rate ? (((price * rate)/2.5)).toFixed(2) : price; // Convert price to selected currency
  };

  return { currency, setCurrency, convertPrice }; // Return currency state, setter, and conversion function
};

// CurrencyProvider component to wrap your application with CurrencyContext.Provider
export const CurrencyProvider = ({ children }) => {
  const currencyData = useCurrency(); // Get currency related data from useCurrency hook
  return (
    <CurrencyContext.Provider value={currencyData}>
      {children}
    </CurrencyContext.Provider>
  );
};

// Custom hook to consume CurrencyContext in functional components
export const useCurrencyContext = () => {
  return useContext(CurrencyContext); // Use useContext to access current context value
};
