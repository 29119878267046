import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Order from './Order';
import './styles_css/OrderPage.css';

const OrderPage = () => {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [approvedOrders, setApprovedOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  console.log(orders);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get([process.env.REACT_APP_WEBSITE_URL,'/api/allOrders'].join(""));
        const ordersData = response.data;
        const pendingOrders = ordersData.map(order => ({
          ...order,
          durum: order.durum || 'pending', // Default durum is pending
        }));
        setOrders(pendingOrders || []);
        setFilteredOrders(pendingOrders.filter(order => order.durum === 'pending') || []); // Show only pending orders by default
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrders();
  }, []);

  const approveOrder = async (orderId) => {
  
    const order = orders.find(order => order.id === orderId);
    console.log(order);

    if (!order) {
      console.log(`Order not found with ID: ${orderId}`);
      return;
    }

    for (const cartItem of order.cart) {
      const { groupedInventoryItemIDByColors, selectedProductColor } = cartItem;

      if (!groupedInventoryItemIDByColors[selectedProductColor]) {
        console.log(`No inventory item IDs found for color: ${selectedProductColor}`);
        continue;
      }

      for (const inventoryItemId in groupedInventoryItemIDByColors[selectedProductColor]) {
        const { stock, inventory_item_id } = groupedInventoryItemIDByColors[selectedProductColor][inventoryItemId];
        console.log(`Inventory Item ID: ${inventory_item_id}, Stock: ${stock}`);

        try {
          // Adjust inventory level for each inventory item 
          await axios.post([process.env.REACT_APP_WEBSITE_URL,'/api/adjust-inventory'].join(""), {
            location_id: 64086966323,
            inventory_item_id: inventory_item_id,
            available_adjustment: -cartItem.quantity // Adjust stock by the quantity from the order
          });

          console.log(`Inventory level adjusted for Inventory Item ID: ${inventory_item_id}`);
        } catch (error) {
          console.error('Error adjusting inventory level:', error);
        }
      }
    }

    try {
      // Update order status on the server
      
      await axios.post([process.env.REACT_APP_WEBSITE_URL,'/api/change-order-status'].join(""), {
        orderInfo:order
      });


      // Update the state locally
      /*
      const updatedOrders = orders.map(order =>
        order.id === orderId ? {
          ...order,
          orderId,
          newPrice: order.totalPrice, // needs change
          newVergi: order.taxesPercentage,
          newIndirim: order.discountPercentage,
          newStatus: 'approved',
          status: 'approved',
          indirim: order.discountPercentage,
          vergi: order.taxesPercentage
        } : order
      );
      setOrders(updatedOrders);
      setFilteredOrders(updatedOrders.filter(order => order.durum === 'pending'));
*/


      // console.log('Order status updated successfully');

      // Save updated orders to the server
      //await axios.post('https://rgbrand.eternavisio.com/api/save-orders', { orders: updatedOrders });

      console.log('Order status updated successfully');
    } catch (error) {
      console.error('Error updating order status:', error);
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      
      await axios.delete([process.env.REACT_APP_WEBSITE_URL,`/api/delete-order/${orderId}`].join(""));
      const updatedOrders = orders.filter(order => order.id !== orderId);
      setFilteredOrders(updatedOrders.filter(order => order.durum === 'pending'));
      setOrders(updatedOrders);
      console.log('Order deleted successfully');
    } catch (error) {
      console.error('Error deleting order:', error);
    }
  };

  const displayFilteredOrders = (filterType) => {
    const filtered = orders.filter(order => order.durum === filterType);
    setFilteredOrders(filtered);
  };

  const showPendingOrders = () => {
    displayFilteredOrders('pending');
  };

  const showApprovedOrders = async () => {
    setFilteredOrders(approvedOrders);
    displayFilteredOrders('approved');
  };

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOrders(
      orders.filter(order =>
        (order.id && order.id.toLowerCase().includes(query)) ||
        (order.fullName && order.fullName.toLowerCase().includes(query)) ||
        (order.companyName && order.companyName.toLowerCase().includes(query))
      )
    );
  };


  return (
    <div className="order-page2">
      <h1>Sipariş Yönetimi</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Sipariş Ara..."
          value={searchQuery}
          onChange={handleSearch}
        />
      </div>
      <div className="button-group">
        <button className="filter-button" onClick={showPendingOrders}>Bekleyen Siparişler</button>
        <button className="filter-button" onClick={showApprovedOrders}>Onaylanan Siparişler</button>
      </div>
      <div className="orders">
        {filteredOrders.length > 0 ? (
          filteredOrders.map(order => (
            <Order
              key={order.id}
              order={order}
              //onApprove={() => approveOrder(order.id)}
              onDelete={() => deleteOrder(order.id)}
            />
          ))
        ) : (
          <p>Sipariş Bulunmamaktadır</p>
        )}
      </div>
    </div>
  );
};

export default OrderPage;
