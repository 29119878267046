const productColors = {
    control: (provided) => ({
      ...provided,
      border: '1px solid #000',
      boxShadow: 'none',
      color: '#000',
      alignItems: 'stretch',
      alignSelf: 'stretch',
      flex: 1,
      borderRadius: '4.49px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      padding: '2.7px 4.5px',
      margin: '0 2px',
      fontFamily: 'Futura Md BT, sans-serif',
      fontSize: '12px',
      width: '100%',
    }),
    input: (provided) => ({
      ...provided,
      margin: '0 2px',
      color: '#000',
      width: '100%',
      fontFamily: 'Intern, sans-serif',
      fontSize: '12px',
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
      border: '1px solid #000',
      backgroundColor: '#fff',
      zIndex: 5, // Increased zIndex to ensure menu appears above other elements    
      color:'#000',
      fontFamily: 'Intern, sans-serif',
      fontSize: '12px',
      height: 'auto',
      position: 'absolute',
      maxHeight: 'auto',
      width: '100%',

    }),
    option: (provided, state) => ({
      ...provided,
      width: '100%',

      backgroundColor: state.isSelected ? '#000' : '#fff',
      '&:hover': {
        backgroundColor: state.isSelected ? '#000' : '#eee',
        color: state.isSelected ? '#fff' : '#000',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#000',
      width: '100%',
      fontFamily: 'Intern, sans-serif',
      fontSize: '12px',
    }),
    placeholder: (provided) => ({
      ...provided,
      color: '#000',
      width: '100%',
      fontFamily: 'Intern, sans-serif',
      fontSize: '12px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      color: '#000',
      width: '100%',
      // Change color to white or any other color you prefer
    })
    
  };

  export default productColors;