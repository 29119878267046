// OverlayComponent.js
import React, { useEffect, useState } from 'react';
import './styles_css/OverlayComponent.css'; // Ensure you have corresponding CSS file
import leftArrow from "./leftArrow.svg";
import rightArrow from "./rightArrow.svg";

const OverlayComponent = ({ showOverlay, selectedProduct, handleCloseOverlay, currency, convertPrice }) => {
    let imagesArray = [];
    const [mainImage, setMainImage] = useState('/no-image.svg');
    const [selectedImageIndex, setSelectedIndex] = useState();

    useEffect(() => {
        if (selectedProduct !== undefined && selectedProduct !== null) {
            if (selectedProduct.photoUrl !== null && selectedProduct.photoUrl !== '') {
                setMainImage(selectedProduct.photoUrl);
                setSelectedIndex(0);

            }
        }
    }, [selectedProduct]);

    function changeImage(url) {
        if (url !== undefined && url !== null) {
            setMainImage(url);
            
        }
    }

    function clickOnImage(direction){
        let newIndex = selectedImageIndex + direction;
        console.log(newIndex);
        if(newIndex >= imagesArray.length){
            setSelectedIndex(0);
            newIndex = 0;
        }
        else if(newIndex < 0){
            setSelectedIndex(imagesArray.length-1);
            newIndex = imagesArray.length-1;
        }
        else{
            setSelectedIndex(newIndex);
        }
        console.log(newIndex);
        changeImage(imagesArray[newIndex]);
        
    }

    function setElement(event){
        var element = event.target.parentNode;
        
        // Find the parent <ul> element of the clicked <li>
        let parentNode = element.parentNode;
        //setImagesHTML(parentNode)
        
        // Find the index of the clicked <li> within its parent <ul>
        let index = Array.prototype.indexOf.call(parentNode.children, element);
        
        // Log the index for demonstration
        setSelectedIndex(index);
          
    }

    if (!showOverlay || !selectedProduct) return null;


    if (selectedProduct?.images && selectedProduct.images.length > 0) {
        selectedProduct.images.map(item => {
            const imageUrl = item?.src;

            if (imageUrl !== undefined && imageUrl !== null && imageUrl !== '' && !imagesArray.includes(imageUrl)) {
                imagesArray.push(imageUrl);
            }
        });
    }

    return (
        <>
            <div onClick={handleCloseOverlay} className="overlay-background5"></div>
            <div className="product-overlay-page5">
                <div className="innner-background5">
                    <div className="header5" onClick={handleCloseOverlay}>
                        <img className="x-circle-icon5" alt="Close" src="tx-circle.svg" />
                    </div>
                    <div className="frame-parent5">
                        <div className="title-wrapper5">
                            <b className="title5">{selectedProduct.title}</b>
                        </div>
                        <div className="frame-group5">
                            <div className="image-frame-parent5">
                                <div className="image-frame5">
                                    <img className="image-icon5" alt={selectedProduct.title} src={mainImage} />
                                </div>
                            </div>
                                

                            <div className="frame-container5">
                                <div className="alert-circle-parent5">
                                    <img className="alert-circle-icon5" alt="" src="info-circle.svg" />
                                    <div className="product-info5">
                                        <div className="price-container5">
                                            <div className="price-inner-container5">
                                                <div className="price5">SKU:</div>
                                                {selectedProduct.skus.map(sku => (
                                                    <div key={sku} className="price-background-container5">
                                                        <b className="more-details5" data-scroll-to="priceText">{sku}</b>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-div5">
                                    <div className="more-details-wrapper5">
                                        <b className="more-details5">Varyasyonlar</b>
                                    </div>
                                    <div className="frame-parent15">
                                        <div className="price-container-wrapper5">
                                            <div className="price-container15">
                                                <div className="price-inner-container15">
                                                    <div className="price5">Beden:</div>
                                                    <div className="price-background-container-parent5">
                                                        {Array.from(new Set(selectedProduct.variants.map(variant => variant.size))).map(size => (
                                                            <div key={size} className="price-background-container15">
                                                                <b className="more-details5" data-scroll-to="priceText">{size}</b>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="product-info15">
                                            <div className="price-container25">
                                                <div className="price-inner-container25">
                                                    <div className="price5">Renk:</div>
                                                    <div className="price-background-container-parent5">
                                                        {Array.from(new Set(selectedProduct.variants.map(variant => variant.color))).map(color => (
                                                            <div key={color} className="price-background-container15">
                                                                <b className="more-details5" data-scroll-to="priceText">{color}</b>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="alert-circle-parent5 alert-circle-secondary">
                                    <div className="price-container5">
                                        <div className="price-inner-container5">
                                            <div className="price5">Fiyat:</div>
                                            <div className="price-background-container5">
                                                {selectedProduct.prices.map((price, index) => (
                                                    <b key={index} className="more-details5" data-scroll-to="priceText">
                                                        {convertPrice(price)} {currency}
                                                    </b>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                            <div className='image-frame-outer'>
                                <button onClick={() => clickOnImage(-1)} className='image-scroll-button5'><img className='image-scroll-arrow5 translate-from-right' src={leftArrow}></img></button>
                                <div className="image-frame-wrapper5">
                                    {
                                        imagesArray.map((imageUrl, key) => {
                                            return (
                                                <button type="button" onClick={(event) => {
                                                    changeImage(imageUrl);
                                                    setElement(event);
                                                }} key={key} className="image-frame15">
                                                    <img className="image-icon15" alt={selectedProduct.title} src={imageUrl} />
                                                </button>
                                            )
                                        })
                                    }
                                </div>
                                <button onClick={() => clickOnImage(1)} className='image-scroll-button5'><img className='image-scroll-arrow5 translate-from-left' src={rightArrow}></img></button>
                            </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OverlayComponent;
