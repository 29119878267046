import axios from 'axios';
import React, { useEffect, useState } from 'react';
import ReactPaginate from "react-paginate";
import { Link, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Select from 'react-select';
import CartPage from './CartPage.js'; // Import CartPage component
import { CurrencyProvider, useCurrency } from './components/CurrencyContext.js';
import LoadingSpinner from './components/LoadingSpinner';
import OrderPage from './components/OrderPage.js';
import OverlayComponent from './components/OverlayComponent.js';
import customStyles from './components/styles_js/custom-styles.js';
import productColors from './components/styles_js/product-colors.js';

import './styles/Apps.css';
import './styles/global.css';


const App = () => {
  const { currency, setCurrency, convertPrice } = useCurrency();
  const [products, setProducts] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productTypes, setProductTypes] = useState([{ value: '', label: 'All Categories' }]);
  const [colors, setColors] = useState([{ value: '', label: 'All Colors' }]);
  const [tags, setTags] = useState([{ value: '', label: 'All Tags' }]);
  const [selectedColor, setSelectedColor] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(12);
  const [totalPages, setTotalPages] = useState(0);
  const [cart, setCart] = useState(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [showOverlay, setShowOverlay] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProductColor, setSelectedProductColor] = useState({});
  const [addedToCart, setAddedToCart] = useState({});
  const [scrollHeight, setScrollHeight] = useState(0);


  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  useEffect(() => {

    const fetchProducts = async () => {
      try {
        const response = await axios.get([process.env.REACT_APP_WEBSITE_URL, '/api/products'].join(""));
        const productsData = response.data.products;

        setProducts(productsData);
        setFilteredProducts(productsData);
        setProductCount(response.data.productCount);
        setTotalPages(Math.ceil(response.data.productCount / pageSize));

        const colorsSet = new Set();
        const productTypesSet = new Set();
        const tagsSet = new Set();

        productsData.forEach(product => {
          product.variants.forEach(variant => colorsSet.add(variant.color));
          productTypesSet.add(product.category);
          product.type.forEach(tag => tagsSet.add(tag));
        });

        setColors([
          { value: '', label: 'Renk' },
          ...Array.from(colorsSet).sort().map(color => ({ value: color, label: color }))
        ]);
        setProductTypes([
          { value: '', label: 'Kategori' },
          ...Array.from(productTypesSet).sort().map(type => ({ value: type, label: type }))
        ]);
        setTags([
          { value: '', label: 'Kategori' },
          ...Array.from(tagsSet).sort().map(tag => ({ value: tag, label: tag }))
        ]);
      } catch (error) {
        console.error('Error fetching product data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [pageSize]);

  useEffect(() => {
    const handleFilterChange = () => {
      let filtered = products;
      if (selectedColor !== '') {
        filtered = filtered.filter(product => product.variants.some(variant => variant.color === selectedColor));
      }
      if (selectedProductType !== '') {
        filtered = filtered.filter(product => product.category === selectedProductType);
      }
      if (selectedTag !== '') {
        filtered = filtered.filter(product => product.type.includes(selectedTag));
      }
      if (searchTerm) {
        filtered = filtered.filter(product =>
          product.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.skus.some(sku => sku.toLowerCase().includes(searchTerm.toLowerCase())) ||
          product.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.type.some(tag => tag.toLowerCase().includes(searchTerm.toLowerCase()))
        );
      }

      setFilteredProducts(filtered);
      setTotalPages(Math.ceil(filtered.length / pageSize));
      setCurrentPage(1);
    };

    handleFilterChange();
  }, [products, selectedColor, selectedProductType, selectedTag, searchTerm, pageSize]);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const addToCart = (product, color, variantId) => {
    const existingProductIndex = cart.findIndex(
      (item) => item.variantId === variantId && item.selectedProductColor === color
    );

    if (existingProductIndex !== -1) {
      // Product already exists, update its color
      const updatedCart = [...cart];
      updatedCart[existingProductIndex].quantity += 1; // Increase quantity
      setCart(updatedCart);
    } else {
      // Product doesn't exist, add it to the cart with the color
      const cartItem = { ...product, selectedProductColor: color, variantId, quantity: 1 };
      setCart([...cart, cartItem]);
      localStorage.setItem('cart', JSON.stringify([...cart, cartItem])); // Update local storage
    }
  };

  const handleAddToCart = (product) => {
    const selectedColorForProduct = selectedProductColor[product.id];
    if (!selectedColorForProduct) {
      alert('Please select a color first.');
      return;
    }

    const selectedVariant = product.variants.find(variant => variant.color === selectedColorForProduct);
    if (selectedVariant) {
      addToCart(product, selectedColorForProduct, selectedVariant.variant_id);
      console.log(`Product added to cart: ${selectedVariant.variant_id} - ${product.id} - ${selectedColorForProduct}`);
      setAddedToCart((prevState) => ({
        ...prevState,
        [product.id]: true
      }));

      // Set a timeout to revert the state after 2 seconds
      setTimeout(() => {
        setAddedToCart((prevState) => ({
          ...prevState,
          [product.id]: false
        }));
      }, 1000);
    } else {
      alert('Selected color is not available.');
    }
  };



  const handleColorChange = (productId, color) => {
    setSelectedProductColor({ ...selectedProductColor, [productId]: color });
  };


  const handleInfoClick = (product) => {
    setScrollHeight(window.scrollY);

    window.scrollTo(0, 0);

    setSelectedProduct(product);
    setShowOverlay(true);
  };

  const handleCloseOverlay = () => {
    window.scrollTo(0, scrollHeight);
    setShowOverlay(false);
  };

  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const productsToShow = filteredProducts.slice(startIndex, endIndex);
  productsToShow.filter(product => product.totalStock > 0 && product.variants.some(variant => variant.stock > 0));

  const handleCurrencyChange = (event) => {
    setCurrency(event.target.value);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <CurrencyProvider>
      <Router>
        <div className="high-fed-order-page">
          <div className="image-2-parent">
            <img className='image-2-icon' alt='logo' src='image 2.png'></img>
            <Link to="/" className="wholesale">TOPTAN</Link>
            <div className="frame-parent">
              {/* <div className="total-products-310-wrapper">
              <div className="sku-number">{productCount} Products</div>
            </div> */}

              <div className="tl-parent">
                <label htmlFor="currency">
                  <select id="wholesale" value={currency} onChange={handleCurrencyChange}>
                    <option className="wholesale" value="TRY">TRY</option>
                    <option className="wholesale" value="USD">USD</option>
                    <option className="wholesale" value="EUR">EUR</option>
                  </select>
                </label>
              </div>
            </div>
          </div>

          <Routes>
            <Route path="/" element={
              <>
                <section className="frame-group">
                  <div className="css-b62m3t-containerr">

                    <Select
                      options={tags}
                      value={tags.find(tag => tag.value === selectedTag)}
                      onChange={(selectedOption) => setSelectedTag(selectedOption ? selectedOption.value : '')}
                      styles={customStyles}
                    />
                  </div>

                  <div className='search-and-cart-icon'>

                    <div className="searchbar-placeholder-parent">
                      <input
                        className="searchbar-placeholder"
                        type="text"
                        placeholder="Search by SKU, Name, Category, or Tag"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                      <img className="search-icon" alt="Search Icon" src="search.svg" />
                    </div>

                    <Link to="/cart" className="frame-container" id="frameContainer2">
                      <Link className="wrapper">
                        <span className="wholesale">{cart.length}</span>
                      </Link>
                      <img className="shopping-bag-icon" alt="Shopping Bag" src="shopping-bag.svg" />
                    </Link>
                  </div>

                </section>


                <section className="instance-parent">
                  {productsToShow.map(product => (
                    <div key={product.id} className="frame-div">
                      <div className="frame-parent1">
                        <div className="image-1-wrapper">
                          {(product.photoUrl !== null && product.photoUrl !== '') ? (
                            <img className="image-1-icon" alt={product.title} src={product.photoUrl} />
                          ) : (
                            <img className="image-1-icon" alt={product.title} src='/no-image.svg' />
                          )}
                        </div>
                        <img className="alert-circle-icon" alt="" src="alert-circle.svg" id="alertCircleIcon" onClick={() => handleInfoClick(product)} />
                      </div>

                      <div className="frame-parent2">
                        <div className="title-wrapper_of_product">
                          <div className="title_of_product">{product.title}</div>
                        </div>
                        <div className="frame-wrapper">
                          <div className="frame-parent3">
                            <div className="sku-parent">
                              <div className="sku">SKU:</div>
                              <div className="sku-number-wrapper">
                                <div className="sku-number">{product.skus.join(', ')}</div>
                              </div>
                            </div>
                            <div className="fiyat-parent">
                              <div className="fiyat">Fiyat:</div>
                              <div className="sku-number-wrapper">
                                <div className="sku-number">{convertPrice(product.prices[0])} {currency}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="frame-parent4">
                        <Select
                          className='full-width-select'
                          styles={productColors}
                          placeholder="Seçiniz"
                          options={Object.keys(product.groupedByColor).map(color => {
                            // Check if any variant with the given color has stock greater than 0
                            const hasStock = product.variants.some(variant => variant.color === color && variant.stock > 0);
                            return {
                              value: color,
                              label: color,
                              isDisabled: !hasStock // Disable the option if no stock is available
                            };
                          })}
                          onChange={(selectedOption) => handleColorChange(product.id, selectedOption.value)}
                          menuPlacement="auto"
                          isDisabled={false}
                        />
                        <div
                          className={`sepet-ekle-wrapper ${addedToCart[product.id] ? 'added' : ''}`}
                          onClick={() => handleAddToCart(product)}
                        >
                          <div className="wholesale">
                            {addedToCart[product.id] ? 'Sepete Eklendi' : 'Sepete Ekle'}
                          </div>
                        </div>


                      </div>

                    </div>
                  ))}
                </section>

                <OverlayComponent
                  showOverlay={showOverlay}
                  selectedProduct={selectedProduct}
                  handleCloseOverlay={handleCloseOverlay}
                  currency={currency}
                  convertPrice={convertPrice}
                />

                <footer className="high-fed-order-page-inner">
                  <div className="frame-parent45">
                    <ReactPaginate
                      pageCount={totalPages}
                      pageRangeDisplayed={1}
                      marginPagesDisplayed={1}
                      previousLabel={
                        <div className="vector-wrapper">
                          <img
                            className="vector-icon"
                            loading="lazy"
                            alt=""
                            src="/left-vector.svg"
                          />
                        </div>
                      }
                      nextLabel={
                        <div className="vector-wrapper">
                          <img
                            className="vector-icon"
                            loading="lazy"
                            alt=""
                            src="/right-vector.svg"
                          />
                        </div>
                      }
                      containerClassName={"container_piginate"}
                      pageLinkClassName={"wholesale_piginate"}
                      activeClassName={"frame_piginate"}
                      onPageChange={handlePageChange}
                      renderOnZeroPageCount={null}
                    />
                  </div>
                </footer>

              </>
            } />
            <Route path="/cart" element={<CartPage
              cart={cart}
              setCart={setCart}
              currency={currency}
              convertPrice={convertPrice} />} />
            <Route path="/orders" element={<OrderPage
              currency={currency}
              convertPrice={convertPrice}
            />} />
          </Routes>
        </div>
      </Router>
    </CurrencyProvider>
  );
};

export default App;
